import React from 'react';

function About() {
    return (
        <div>
            <h1>About Our Lab</h1>
            <p>Our lab focuses on cutting-edge research in [Your Research Area].</p>
        </div>
    );
}

export default About;
